*{
  padding:0;
  margin:0;
  box-sizing: border-box;
  font-size:calc(.6rem + .2vw)
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.information{
  width:70%;
  max-width: 1000px;
  margin:0px auto;
  height:100%;
  min-height:100vh;
  background:white;
  padding:30px 40px 50px 40px;
  margin:0px auto;
  min-width:800px;

 
}
@media print {
 .information {width:100%; padding:0px 40px 50px 40px;}
 .agree{
  margin-top:-120px;
}
}
.agree{
  position:relative;

  padding:30px 0px 10px 0px;

}